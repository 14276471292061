<template>
  <form class="individual flex" @submit.prevent="individualSubmit">
    <div class="add main-block col-lg-4 col-md-12 col-12">
      <div class="add__box main-block__head">
        <h1 class="add__box-title main-block__title">Информация Физ.лицо</h1>
        <button type="submit" class="main-block__btn default-btn">Сохранить</button>
      </div>
      <ul class="add__items">
        <!--        <li class="add__item flex">-->
        <!--          <p class="add__key">Фамилия *</p>-->
        <!--          <input type="text" class="add__value" v-model="surname" placeholder="Фамилия">-->
        <!--        </li>-->
        <li class="add__item flex">
          <p class="add__key">Имя *</p>
          <input v-model="name" type="text" class="add__value" placeholder="Имя" />
        </li>
        <!--                <li class="add__item flex">-->
        <!--                    <p class="add__key">Отчество *</p>-->
        <!--                    <input type="text" class="add__value" v-model="text" placeholder="Отчество" required>-->
        <!--                </li>-->
        <li class="add__item flex">
          <p class="add__key">Дата *</p>
          <!--          <input type="text" class="add__value" v-model="date" placeholder="Дата" >-->
          <b-calendar
            v-show="dateShow"
            v-model="date"
            class="add__calendar"
            :hide-header="true"
            @context="onContext"
          />
          <input
            v-model="date"
            type="text"
            class="add__value"
            placeholder="Дата"
            @click="dateShow = !dateShow"
            @input="dateShow = false"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Email * &nbsp; (один и более через запятую)</p>
          <input v-model="email" type="text" class="add__value" placeholder="Email" />
        </li>
        <!--        <li class="add__item flex">-->
        <!--          <p class="add__key">Город проживания *</p>-->
        <!--          <input type="number" class="add__value" placeholder="Город" >-->
        <!--        </li>-->
        <li class="add__item flex">
          <p class="add__key">№ телефона * &nbsp; (один и более через запятую)</p>
          <input v-model="phone" type="text" class="add__value" placeholder="+7"/>
          <!-- <the-mask
            v-model="phone"
            type="text"
            mask="+7 (###) ### ####,  +7 (###) ### ####,  +7 (###) ### ####,  +7 (###) ### ####"
            class="add__value add__value_aligin"
            placeholder="+7"
          /> -->
        </li>
        <li class="add__item flex">
          <p class="add__key">ИИН *</p>
          <the-mask
            v-model="iin"
            type="text"
            mask="### ### ### ###"
            class="add__value add__value_aligin"
            placeholder="ИИН"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">№ уд.личности *</p>
          <input
            v-model="doc_number"
            type="number"
            class="add__value add__value_aligin"
            placeholder="№"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">IBAN *</p>
          <input
            v-model="bin"
            type="number"
            class="add__value add__value_aligin"
            placeholder="IBAN"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Договор Контерагента *</p>
          <input
            v-model="agreement"
            type="number"
            class="add__value add__value_aligin"
            placeholder=""
          />
        </li>
      </ul>
    </div>
    <AddImgBlocks class="col-lg-4 col-md-12 col-12" />
    <AddressComp
      class="add main-block col-lg-3 col-md-12 col-12"
      :counterparty-data="counterpartyData"
      @cityId="city_id = $event"
    />
    <div v-if="dateShow" class="main-close" @click="dateShow = false" />
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    AddImgBlocks: () => ({
      component: import("../../../components/add/driver/addImg"),
    }),
    AddressComp: () => ({
      component: import("../addressComp"),
    }),
  },
  props: {
    counterpartyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dateShow: false,
      surname: null,
      name: null,
      date: null,
      email: null,
      city_id: null,
      phone: null,
      iin: null,
      doc_number: null,
      bin: null,
      document_image: null,
      address: "",
      street: "",
      apartment_number: 2222222222222222222222n,
      number: 11111111111111110,
      organization: "",
      full_name: "",
      agreement: null,
      password: "null"
    };
  },
  async created() {
    await this.$store.dispatch("getCitiesProducts");
  },
  methods: {
    async individualSubmit() {
      if (this.$route.query.id) {
        this.$api
          .post("/web/edit-counteragent", {
            type: "individual",
            city_id: +this.city_id || "",
            name: this.name,
            surname: this.surname,
            organization: this.organization,
            full_name: this.full_name,
            bin: this.bin,
            agreement: this.agreement,
            phone: this.phone,
            email: this.email,
            password: 12345,
            employee_id: this.$route.query.id,
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getContragentsList");
            this.$router.push("/database/counterparties");
          })
          .catch((e) => {
            this.$store.commit("setToastData", {
              text: e.response.data.message,
              position: "error",
            });
            this.$toast.error(<toast-block />);
            if (
              e.message.split(" ").reverse()[0] === 500 ||
              e.message.split(" ").reverse()[0] === 401
            ) {
              this.$router.push(`/error/${e.message.split(" ").reverse()[0]}`);
            }
          });
      } else {
        this.$api
          .post("/web/add-contragent", {
            type: "individual",
            organization: this.organization,
            full_name: this.full_name,
            bin: this.bin,
            name: this.name,
            surname: this.surname,
            agreement: this.agreement,
            phone: this.phone,
            city_id: +this.city_id || "",
            address: this.address,
            street: this.street,
            apartment_number: this.apartment_number,
            number: this.number,
            email: this.email
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getContragentsList");
            this.$router.push("/database/counterparties");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
    onContext() {
      this.dateShow = false;
    },
  },
  computed: {
    ...mapState(["imagesAdd", "citiesProducts"]),
  },
  watch: {
    "$route.query.id"() {
      if (!this.$route.query.id) {
        this.email = "";
        this.name = "";
        this.surname = "";
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.name = this.counterpartyData.name;
      this.surname = this.counterpartyData.surname;
      this.email = this.counterpartyData.email;
      this.date = this.counterpartyData.updated_at;
      this.phone = this.counterpartyData.phone;
      this.bin = this.counterpartyData.bin;
    }
  },
};
</script>

<style scoped>
.individual {
  align-items: flex-start;
  flex-wrap: wrap;
}

.add {
  min-height: auto;
  padding-bottom: 30px;
}

.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
</style>
